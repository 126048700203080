.header {
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
}

.LuigisBox .product-list {
  margin-top: 150px !important;
}

.mainSwiper,
.slideB {
  position: relative;
}
.mainSwiper .container,
.slideB .container {
  height: 100%;
  display: flex;
  align-items: center;
}

.slideB {
  height: 500px;
  overflow: hidden;
}
.slideB .mainSwiper__right {
  right: 0;
}
.slideB .mainSwiper__left {
  left: 0;
  transform: none;
}

.mainSwiper {
  max-height: 677px;
  min-height: 450px;
  height: 90vh;
  overflow: hidden;
}
.mainSwiper .container {
  overflow: hidden;
}
.mainSwiper .swiper-button-next,
.mainSwiper .swiper-button-prev {
  right: 30px;
}
.mainSwiper .swiper-button-prev {
  left: 30px;
}
@media (min-width: 1400px) {
  .mainSwiper .swiper-button-next,
.mainSwiper .swiper-button-prev {
    left: calc(50% + 590px);
    right: initial;
  }
  .mainSwiper .swiper-button-prev {
    right: calc(50% + 590px);
    left: initial;
  }
}
@media (max-width: 576px) {
  .mainSwiper .swiper-button-next,
.mainSwiper .swiper-button-prev {
    display: none;
  }
}

.mainSwiper__left {
  position: absolute;
  left: -598px;
  top: 0;
  z-index: 5;
}
@media (min-width: 1400px) {
  .mainSwiper__left {
    left: -658px;
  }
}

.mainSwiper__right {
  position: absolute;
  right: 0;
  top: 0;
}

.mainSwiper__img {
  position: absolute;
  left: -20px;
  top: 0;
  height: 100%;
}

.mainSwiper__txt,
.slideB__txt {
  margin-left: auto;
  width: 100%;
  position: relative;
  z-index: 10;
}
.mainSwiper__txt h1,
.mainSwiper__txt h2,
.slideB__txt h1,
.slideB__txt h2 {
  line-height: 1.1;
  font-weight: 800;
}

.slideB__txt {
  max-width: 620px;
}

.mainSwiper__txt {
  margin-top: 100px;
}
.mainSwiper__txt h1,
.mainSwiper__txt h2 {
  font-size: 50px;
  margin-bottom: 25px;
  font-style: normal;
}
.mainSwiper__txt ul,
.mainSwiper__txt ol,
.mainSwiper__txt p {
  font-size: 22px;
  margin-bottom: 0;
}
.mainSwiper__txt .btn {
  margin-top: 45px;
}
@media (max-width: 992px) {
  .mainSwiper__txt {
    margin-right: auto;
    width: 75%;
  }
}
@media (max-width: 576px) {
  .mainSwiper__txt {
    margin-top: 60px;
    margin-right: auto;
    width: 90%;
  }
}

.slider-main__img {
  position: absolute;
  height: calc(100% - 100px);
  left: 0;
  top: 100px;
}

.slideB__txt h2 {
  font-size: 35px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.slideB__txt ul,
.slideB__txt ol,
.slideB__txt p {
  text-transform: uppercase;
  font-weight: 600;
}
.slideB__txt li {
  margin-bottom: 10px;
}
.slideB__txt ul,
.slideB__txt ol {
  padding-left: 30px;
}

.slideB__bottom {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 1240px) {
  .mainSwiper__txt {
    padding-right: 200px;
    max-width: 620px;
    margin-top: 100px;
  }

  .slideB__txt {
    padding-right: 100px;
  }
}
@media (max-width: 1240px) {
  .slideB__txt {
    max-width: 520px;
  }

  .mainSwiper__img {
    left: -120px;
  }
}
@media (max-width: 1240px) {
  .mainSwiper__img {
    position: static;
    max-width: 400px;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .slideB .mainSwiper__right {
    right: initial;
    left: 45%;
  }

  .slideB__txt {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-right: auto;
    max-width: 500px;
  }

  .slideB {
    height: auto;
  }
  .slideB .container {
    display: block;
  }

  .mainSwiper {
    max-height: 450px;
  }

  .mainSwiper__left {
    left: -850px;
  }

  .mainSwiper__right {
    height: 100%;
  }

  .mainSwiper__txt h1,
.mainSwiper__txt h2 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .mainSwiper__txt ul,
.mainSwiper__txt ol,
.mainSwiper__txt p {
    font-size: 16px;
    margin-bottom: 0;
  }
  .mainSwiper__txt .btn {
    margin-top: 30px;
  }

  .slideB__txt h2 {
    font-size: 20px;
  }

  .new {
    font-size: 16px;
  }

  .mainSwiper {
    max-height: 540px;
    min-height: 540px;
    margin-top: 0;
  }
  .mainSwiper .container {
    padding-bottom: 20px;
    display: block;
  }

  .slider-main__img {
    height: 250px;
    position: relative;
    left: 50%;
    transform: translateX(-45%);
    margin-top: 100px;
    top: 0;
  }

  .mainSwiper__txt {
    margin-top: 15px;
    text-align: center;
    width: 100%;
  }
  .mainSwiper__txt h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .mainSwiper__txt .btn {
    margin-top: 15px;
    margin-left: 0;
  }

  .mainSwiper__right {
    right: -230px;
  }
}
@media (max-width: 576px) {
  .mainSwiper__left {
    height: 400px;
    left: -550px;
  }

  #mainSwiper .mainSwiper__right {
    right: -170px;
    height: calc(100% - 100px);
    top: 100px;
  }

  .slideB .mainSwiper__right {
    display: block;
  }

  .slideB .mainSwiper__left {
    display: block;
    left: -100px;
  }
}
@media (min-width: 576px) {
  .col-d7 {
    flex: 0 0 186px;
    max-width: 186px;
  }
}
.card-1 {
  display: block;
  position: relative;
  text-align: center;
  color: #000;
}
.card-1 img {
  max-width: 100%;
  border: 1px solid #E2E2E2;
}
.card-1 h3 {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  left: 0;
  text-transform: uppercase;
}

.is--desktop .card-1:hover {
  border-color: #0064C5;
  color: #0064C5;
  background: #EFF7FF;
}
.is--desktop .card-1:hover img {
  box-shadow: 0px 0px 0px 2px #0064C5;
}

.container--map {
  max-width: 1000px;
}